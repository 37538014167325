<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="@/assets/images/logo/mit.png">
        <Locale class="ml-3" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            src="@/assets/images/pages/register-v2.svg"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div class="d-flex justify-content-center mb-1">
            <b-spinner
              v-if="load === 'true'"
              class="mr-1"
              variant="primary"
            />
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Welcome') }}
          </b-card-title>
          <b-alert
            variant="primary"
            show
            class="mt-1"
          >
            <div class="alert-body font-medium-1">
              <p>Réservez votre place dès maintenant</p>
              <p class="font-small-3">
                Vous êtes nouveau, C'est facile! Débuter votre demande d’admission
                maintenant.
              </p>
            </div>
          </b-alert>
          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Nom"
                    label-for="nom"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nom"
                      rules="required"
                    >
                      <b-form-input
                        id="nom"
                        v-model="nom"
                        name="nom"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nom"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Prénom"
                    label-for="prenom"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="prenom"
                      rules="required"
                    >
                      <b-form-input
                        id="prenom"
                        v-model="prenom"
                        name="prenom"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Prénom"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="email"
                        name="email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Téléphone"
                    label-for="telephone"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Téléphone"
                      rules="required"
                    >
                      <b-form-input
                        id="telephone"
                        v-model="telephone"
                        name="telephone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Téléphone"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('Password')"
                    label-for="a-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|min:8"
                    >
                      <b-form-input
                        id="a-password"
                        v-model="username"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- confirm password -->
                <b-col cols="6">
                  <b-form-group
                    :label="$t('Confirm Password')"
                    label-for="password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required|confirmed:Password"
                    >
                      <b-form-input
                        id="ac-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        placeholder="Confirm Password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                Inscription
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>vous avez déja un compte</span>
            <b-link :to="{ name: 'auth' }">
              <span>&nbsp;Connectez-vous</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BCardTitle,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSpinner,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      load: 'false',
      prenom: '',
      nom: '',
      telephone: '',
      email: '',
      password: '',
      username: '',
      // sideImg: require('@/assets/images/pages/register-v2.svg'),
      required,
    }
  },
  computed: {
    validation() {
      return this.telephone.length > 4 && this.telephone.length < 13
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          this.load = 'true'
          await axios
            .post(
              '/auth/register/',
              {
                nom: this.nom,
                prenom: this.prenom,
                email: this.email,
                password: this.password,
                telephone: this.telephone,
              },
            )
            .then(() => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Connectez-vous pour accéder à votre espace')
              }, 1500)
              this.$router.push('/login')
            })
            .catch(err => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', err.toString())
              }, 1000)
            })
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
